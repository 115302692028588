import Session from "../auth/Session"

const AuthFunctions = {
    callbacks: [],
    signInAsync: async function (username, password) {
        const response = await fetch(`api/Account/token`, {
            method: "POST",
            body: JSON.stringify({ "Username": username, "Password": password }),
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        return data;
    },
    isCompleteUserOrAboveAsync: async function () {
        const token = Session.getToken();
        const response = await fetch(`api/Admin/isCompleteUserOrAboveAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    },
    signOut: function () {
        Session.removeUserSession();
        return Session.getToken() === null;
    },
    getAuthenticatedAsync: async function () {
        const token = Session.getToken();
        const response = await fetch(`api/Account/IsAuthenticated`, {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    },
    isAdminAsync: async function () {
        const token = Session.getToken();
        const response = await fetch(`api/Admin/IsAdminAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    },
    isTabbAdminAsync: async function () {
        const token = Session.getToken();
        const response = await fetch(`api/Admin/isTabbAdminAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    },
    AdminRoleId: '3057196f-40ed-4cdd-b1ef-5c040a1ce311'
}

export default AuthFunctions;